header {
  border-bottom: 5px solid black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

header img {
  width: 100px;
  height: auto;
  margin: 0 auto;
}

.container {
  padding: 2em;
}

.section {
  margin-bottom: 30px;
}

h2 {
  font-weight: 700;
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  font-weight: 500;
  line-height: 1.4em;
  opacity: 0.8;
}

.image-section img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.metrics {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.metric {
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  flex: 1;
  margin: 0.5em;
  padding: 1em;
  min-width: 140px;
  background-color: black;
}

.metric h3 {
  margin: 0;
  font-size: 2em;
  color: white;
  font-weight: 700;
}

.metric p {
  margin: 0.5em 0 0 0;
  font-weight: 600;
  color: white;
}

.clients {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.clients div {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  border: 1px solid;
  border-radius: 50%;
}

@media (min-width: 768px) {
  .clients div {
    height: 100px;
    width: 100px;
  }
}


.customer {
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  flex: 1;
  margin: 0.5em;
  padding: 1em;
  min-width: 140px;
  background-color: rgb(255, 255, 255);
  max-width: 200px;
  max-height: 200px;
  height: 200px;
  width: 200px;
}

.founder {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.founder img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1em;
}

.founder div {
  flex: 1;
}

footer {
  text-align: center;
  padding: 1em 0;
  color: white;
}

@media (min-width: 768px) {
  .main-content {
    flex-direction: row;
  }
}

